// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
import { FreeMode } from 'swiper/modules'
import { useState, useEffect, useRef } from 'react'
import Card from '../../components/card/Card'
import SliderFilters from '../../components/sliderFilters/SliderFilters'
import SliderNavigation from '../../components/sliderNavigation/SliderNavigation'
import useAppState from "../../utils/themeContext"
import projects from "../../datas/projects.json"

// Import Swiper styles
import 'swiper/css'

import "./_portfolio.scss"

const Portfolio = () => {
    const { state } = useAppState()
    const sliderRef = useRef(null)
    const [activeProjects, setProjects] = useState(projects)

    useEffect(() => {
        let filteredProjects = projects
        if (state.portfolio.filter !== "all") {
            filteredProjects = projects.filter(project => project.tag.value === state.portfolio.filter)
        }
        setProjects(filteredProjects)
    }, [state.portfolio.filter])

    return(
        <div className='portfolio'>
            {state.deviceType === "mobile" ?
                <>
                    <SliderFilters othersClass='portfolio__filters'/>
                    <ul className='portfolio__list'>
                        {activeProjects.map((project, index) => (
                            <li
                                key={`card-${state.portfolio.filter}-${project.id}`} 
                                className='portfolio__list__item'
                                style={{animationDelay:`${0.2 * index}s`}}
                            >
                                <Card 
                                    title={project.title}
                                    tag={project.tag.label}
                                    id={project.id}
                                />
                            </li>
                        ))}
                    </ul>
                </>
                : 
                <>
                    <div className='portfolio__navigation'>
                        <div className='portfolio__filters'>
                            <SliderFilters/>
                        </div>
                        <SliderNavigation sliderRef={sliderRef}/>
                    </div>
                    <Swiper
                        slidesPerView={"auto"}
                        freeMode={{
                            enabled: true,
                            sticky: true
                        }}
                        className='portfolio__slider'
                        modules={[FreeMode]}
                        ref={sliderRef}
                    >
                        {activeProjects.map((project, index) => (
                            <SwiperSlide 
                                key={`card-${state.portfolio.filter}-${project.id}`} 
                                className='portfolio__slider__item'
                                style={{animationDelay:`${0.2 * index}s`}}
                            >
                                <Card 
                                    title={project.title}
                                    tag={project.tag.label}
                                    id={project.id}
                                    link={project.link}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
            }
        </div>
    )
}

export default Portfolio