import Tag from "../tag/Tag"
import useAppState from "../../utils/themeContext"

import "./_card.scss"

const Card = ({title, tag, id, link}) => {
    const { state } = useAppState();

    return (
        <a className="card" href={link}>
            <div className='card__illustration'>
                <img src={require(`../../assets/images/projects/${id}/preview.jpg`)}/>
                {state.deviceType === 'mobile' &&
                    <Tag firstcolor="light" secondcolor="primary" layoutType="fill" othersClass='card__illustration__tag'>{tag}</Tag>
                }
            </div>
            <div className='card__details'>
                <h2 className='card__details__title'>{title}</h2>
                {state.deviceType !== 'mobile' &&
                    <Tag firstcolor="light" secondcolor="primary" layoutType="fill" othersClass='card__details__tag'>{tag}</Tag>
                }
            </div>
        </a>
    )
}

export default Card