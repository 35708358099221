import Tag from "../tag/Tag"
import "./_socials.scss"

const Socials = ({color, othersClass=""}) => {
    return(
        <div className={`socials ${othersClass}`}>
            <a href="https://www.linkedin.com/in/lise-denis/" className="socials__item">
                <Tag firstcolor="light" secondcolor="transparent" decorationType="fill">LinkedIn</Tag>
            </a>
            <a href="https://www.behance.net/schwepps0403f9" className="socials__item">
                <Tag firstcolor="light" secondcolor="transparent" decorationType="fill">Behance</Tag>
            </a>
            {/* <a href="#" className="socials__item">
                <Tag firstcolor="light" secondcolor="transparent" decorationType="fill">Mon CV</Tag>
            </a> */}
        </div>
    )
}

export default Socials