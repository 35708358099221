import projects from "../datas/projects.json"

const getTags = () => {
    const uniqueTags = new Map();
    
    projects.forEach(project => {
        const value = project.tag.value.trim().toLowerCase();
        const label = project.tag.label.trim();
        
        if (!uniqueTags.has(value)) {
            uniqueTags.set(value, label);
        }
    });

    return Array.from(uniqueTags.entries()).map(([value, label]) => ({ value, label }));
}

export default getTags