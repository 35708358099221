import { NavLink } from "react-router-dom"
import { useLocation } from "react-router-dom"

import "./_navMobile.scss"

const NavMobile = ({ othersClass="" }) => {
    const { pathname } = useLocation();

    return( 
        <nav className={`nav-mobile ${othersClass}`}>
            {pathname === "/portfolio" ?
                <NavLink to="/about" className="nav-mobile__item" label="À propos">
                    À propos
                </NavLink>
                :
                <NavLink to="/portfolio" className="nav-mobile__item" label="Projets">
                    Projets
                </NavLink>
            }
        </nav>
    )
}

export default NavMobile