import Container from "../container/Container"
import Profil from "../../components/profil/Profil"
import Tag from "../../components/tag/Tag"
import NavMobile from "../../components/navMobile/navMobile"

import "./_headerMobile.scss"

const HeaderMobile = () => {
    return(
        <header className="header-mobile">
            <Container layoutType="big">
                <div className="header-mobile__inner">
                    <a className="header-mobile__behance" href="https://www.behance.net/schwepps0403f9" title="Mon profil behance">
                        <Tag layoutType="circle" decorationType="fill">Be</Tag>
                    </a>
                    <Profil othersClass="header-mobile__profil"/>
                    <NavMobile othersClass="header-mobile__nav" />
                </div>
            </Container>
        </header>
    )
}

export default HeaderMobile